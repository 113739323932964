import React from 'react'
import Layout from "../../../../components/layout";
import HeaderPaginas from '../../../../components/headerPaginas'
import Seo from '../../../../components/SEO/SEO';
import CtaServicios from '../../../../components/ctaServicios';

export default function AuditoriaDirigida(props) {

    return (
        <Layout>
            <Seo pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Auditoría OT dirigida" imagen="/img/cabeceras/auditoria-ot-ciberseguridad-bullhost.jpg" />
                <div className="intro-servicio">
                    <p>Has llegado a nuestra zona más potente, a nuestro límite de la fuerza bruta, al sector que más nos motiva: la ciberseguridad industrial.
                    Y para proteger de un ciberataque tu infraestructura industrial conectada primero debes conocer sus vulnerabilidades.</p>
                </div>
            </div>
            <div className="cuerpo-servicio">
                <div className="limitador">
                    <p className="parrafo-destacado">El objetivo de nuestra Auditoria OT es poder evaluar el nivel de seguridad de las barreras de protección y de la infraestructura de tu entorno industrial.
                    En ella debemos tener en cuenta todas las máquinas conectadas (4.0) así como aquellos que se conectan a las mismas. </p>
                    <section className="fila-cards-cpd-propio-caracteristicas">
                       <div className="card-cpd-propio-caracteristicas">
                           <div className="col-imagen" style={{ backgroundImage: "url(/img/seguridad-industrial-bullhost.jpg)" }}>
                               {/* <StaticImage src="../../../static/img/bullhost-cloud-robusto-cpd.jpg" alt="Cloud robusto" /> */}
                           </div>
                           <div className="col-texto">
                               <div>
                                   <h3>Las ventajas: algo más que rentabilidad.</h3>
                                   <p>Si te roban datos: te roban dinero. Y eso es muy importante para tu rentabilidad, pero con la seguridad en entornos industriales te juegas algo más: vidas.
                                    Hay que tener siempre en cuenta, que los ataques dirigidos a sistemas o entornos industriales pueden alterar el correcto funcionamiento de maquinaria pesada, y, debido a esto, se pueden generar graves accidentes en planta que pueden costar vidas.</p>
                                    <p>Con nuestra Auditoria OT dirigida podrás conocer el nivel de riesgo de la infraestructura auditada, sus vulnerabilidades, así como el alcance de las mismas. 
                                    No dejes que un ataque afecte de forma directa a tu capacidad de producción, ve siempre por delante.</p>
                                </div>
                           </div>
                       </div>
                    </section>
                </div>
            </div>
            
            <CtaServicios/>
        </Layout>
    )
}